<script>
/**
 * Widget Component
 */

import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import appConfig from "@/app.config";
import moment from "moment";

export default {
  props: ["telecallers"],
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      filters: {
        project: null,
        startDate: null,
        endDate: null,
        daterange: null,
        telecaller: null,
      },
      projects: [],
    };
  },
  methods: {
    async getProjects() {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/telecallers/projects"
        );
        this.projects = response.data.telecaller_projects;
      } catch (err) {
        console.log(err);
      }
    },
    async loadFilters() {
      this.getProjects();
    },
    getFormatedDate(date) {
      try {
        return moment(date).format("yyyy-MM-DD");
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    getParams(params) {
      let project_ids = this.filters.project
        ? this.filters.project.map((obj) => obj.id)
        : {};
      if (project_ids && project_ids.length > 0) {
        params.project_ids = project_ids;
      }

      let from_date =
        this.filters.daterange && this.filters.daterange.length > 0
          ? this.getFormatedDate(this.filters.daterange[0])
          : null;
      if (from_date) {
        params.from_date = from_date;
      }
      let to_date =
        this.filters.daterange &&
        this.filters.daterange.length > 0 &&
        this.filters.daterange[1]
          ? this.getFormatedDate(this.filters.daterange[1])
          : null;
      if (to_date) {
        params.to_date = to_date;
      }

      let digimrs = this.filters.telecaller
        ? this.filters.telecaller.map((obj) => obj.id)
        : {};
      if (digimrs && digimrs.length > 0) {
        params.telecaller_ids = digimrs;
      }
      this.params = {};
      this.params = params;
      return params;
    },
  },
  mounted() {
    this.loadFilters();
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        let params = this.getParams({});
        this.$emit("filters", params);
      },
    },
    "filters.state"(v) {
      this.getCities(v);
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <label>DigiMR</label>
        <multiselect
          v-model="filters.telecaller"
          :close-on-select="false"
          :options="telecallers"
          :show-labels="false"
          track-by="id"
          label="username"
          :multiple="true"
        ></multiselect>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Project</label>
        <multiselect
          v-model="filters.project"
          :close-on-select="false"
          :options="projects"
          :show-labels="false"
          track-by="id"
          label="title"
          :multiple="true"
        ></multiselect>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group mb-3">
        <label>Date Range</label>
        <br />
        <date-picker
          v-model="filters.daterange"
          range
          append-to-body
          lang="en"
          confirm
        ></date-picker>
      </div>
    </div>
  </div>
</template>
